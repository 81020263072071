<template>
  <div>
    <div
      :style="{ marginLeft: `${(node.pigment.depth - 1) * 20}px` }"
      class="node-content"
    >
      <PhArrowDownRight
        v-if="node.pigment.depth > 1"
        :size="12"
        weight="bold"
      />
      <span :class="{ 'selected-node': isSelected }">{{
        node.pigment.name
      }}</span>
    </div>
    <div v-if="hasChildren" class="children">
      <HierarchyNode
        v-for="child in node.children"
        :key="child.pigment.id"
        :node="child"
        :isSelected="child.pigment.id === selectedPigmentId"
        :selectedPigmentId="selectedPigmentId"
      />
    </div>
  </div>
</template>

<script>
import { PhArrowDownRight } from '@phosphor-icons/vue';
export default {
  name: 'HierarchyNode',
  components: {
    PhArrowDownRight,
  },
  props: {
    node: {
      type: Object,
      required: true,
    },
    isSelected: {
      type: Boolean,
      default: false,
    },
    selectedPigmentId: {
      type: Number,
      required: true,
    },
  },
  computed: {
    hasChildren() {
      return this.node.children && this.node.children.length > 0;
    },
  },
};
</script>

<style scoped>
.node-content {
  display: flex;
  align-items: center;
  padding: 2px 4px;
  border-radius: 3px;
}

.selected-node {
  font-weight: bold;
  color: #1a56db; /* Blue color for better visibility */
  text-decoration: underline;
  background-color: #e1effe; /* Light blue background */
  padding: 2px 4px;
  border-radius: 3px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}
</style>
