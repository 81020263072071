<template>
  <div class="frontend-app">
    <div v-if="healthcheckRoute">
      <pre id="status">{{ healthcheck }}</pre>
    </div>
    <div v-else>
      <div
        class="container mx-auto text-center relative logo-container logo-container-splash"
      >
        <a href="/" aria-label="Mapping Color in History Home Page">
          <img
            class="w-3/4 inline-block color-logo"
            :src="logo"
            alt="Mapping Color in History Logo"
          />
        </a>
        <HamburgerButton
          class="inline-block absolute bottom-12 right-0"
          :open="showMenu"
          @toggle="toggleMenu"
          @close="closeMenu"
        />
      </div>
      <hr class="my-10" />
      <NavMenu
        v-show="showMenu"
        @navigated="toggleMenu"
        @close="closeMenu"
      ></NavMenu>
      <router-view v-show="!showMenu" />
    </div>
    <NavFooter class="mx-auto"></NavFooter>
  </div>
</template>

<script>
import Logo from '../../images/color/color-logo-full.svg';
import HamburgerButton from './components/navigation/HamburgerButton.vue';
import NavMenu from './components/navigation/NavMenu.vue';
import NavFooter from './components/navigation/NavFooter.vue';
import { provide, ref } from 'vue';

export default {
  components: {
    HamburgerButton,
    NavMenu,
    NavFooter,
  },
  data() {
    return {
      showMenu: false,
      healthcheck: {
        status: 'ok',
      },
    };
  },
  computed: {
    logo() {
      return Logo;
    },
    healthcheckRoute() {
      return this.$route.name == 'healthcheck';
    },
  },
  methods: {
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
    closeMenu() {
      this.showMenu = false;
    },
  },
  setup() {
    //setting up data to provide to footer, can be updated by child components via
    // injecting the provided update functions
    const citeType = ref('site');
    function updateCiteType(newType) {
      citeType.value = newType;
      //set unneeded props to null if we're switching types
      if (newType !== 'work') {
        updateWork(null);
        updateAnalysis(null);
      } else if (newType !== 'static') {
        updatePage(null);
      }
    }

    const page = ref(null);
    function updatePage(newPage) {
      page.value = newPage;
    }

    const work = ref(null);
    function updateWork(newWork) {
      work.value = newWork;
    }

    //the getter is needed for reactivity. Otherwise the footer doesn't update when the page's analysis changes.
    const analysis = ref(null);
    function updateAnalysis(newAnalysis) {
      analysis.value = newAnalysis;
    }
    function getAnalysis() {
      return analysis.value;
    }

    provide('work', work);
    provide('updateWork', updateWork);
    provide('updateAnalysis', updateAnalysis);
    provide('getAnalysis', getAnalysis);
    provide('citeType', citeType);
    provide('updateCiteType', updateCiteType);
    provide('page', page);
    provide('updatePage', updatePage);
  },
};
</script>
<style>
.full-width {
  width: 100%;
}
</style>
