<template>
  <div
    class="pigment-image-container"
    :class="{ 'greyed-out': greyedOut }"
    :style="containerStyle"
  >
    <div
      class="pigment-image"
      :style="{ width: `${size}px`, height: `${size}px` }"
    >
      <router-link
        v-if="routeTarget"
        :to="routeTarget"
        tabindex="0"
        class="pigment-link"
        @keydown.enter.stop
      >
        <img
          :src="pigmentPath"
          :alt="pigment.pigment_image?.alt_text"
          :style="{ backgroundImage: `url(${imageSrc})` }"
          class="cutout-image"
        />
      </router-link>
      <img
        v-else
        :src="pigmentPath"
        :alt="pigment.pigment_image?.alt_text"
        :style="{ backgroundImage: `url(${imageSrc})` }"
        class="cutout-image"
      />
      <button
        v-if="showInfoButton"
        class="cursor-pointer pigment-info-modal-button"
        :class="buttonClass"
        @click.stop="handleInfoClick"
        :aria-label="`Show detail information for ${pigment.name}`"
      >
        <svg class="info-icon" viewBox="0 0 25 25">
          <rect x="1" y="1" style="fill: white" width="23" height="23" />
          <path d="M23,2v21H2V2H23 M25,0H0v25h25V0L25,0z" />
          <path d="M12.2,18.4V8.9h2.3v9.5H12.2z M12.2,7.1V4.8h2.3v2.3H12.2z" />
        </svg>
      </button>
    </div>

    <div v-if="showTitle" :class="fontClass" class="pigment-label">
      <router-link
        v-if="routeTarget"
        :to="routeTarget"
        tabindex="0"
        class="pigment-link"
        @keydown.enter.stop
      >
        <span class="pigment-name">{{ pigment.name }}</span>
        <span class="pigment-count" v-if="showCount">
          ({{ pigment.rolled_up_analysis_count }})</span
        >
      </router-link>
      <div v-else>
        <span class="pigment-name">{{ pigment.name }}</span>
        <span class="pigment-count" v-if="showCount">
          ({{ pigment.rolled_up_analysis_count }})</span
        >
      </div>
    </div>
  </div>
</template>

<script>
import PigmentPath from '../../../images/pigment-path.png';

export default {
  name: 'PigmentImage',
  props: {
    pigment: {
      type: Object,
      required: true,
    },
    size: {
      type: Number,
      default: 100,
    },
    greyedOut: {
      type: Boolean,
      default: false,
    },
    showTitle: {
      type: Boolean,
      default: false,
    },
    showCount: {
      type: Boolean,
      default: false,
    },
    fontClass: {
      type: String,
      default: 'text-xl',
    },
    showInfoButton: {
      type: Boolean,
      default: false,
    },
    buttonClass: {
      type: String,
      default: '',
    },
    routeTarget: {
      type: Object,
      default: null,
    },
  },
  emits: ['open-info-modal'],
  methods: {
    handleInfoClick() {
      const nodeStructure = {
        pigment: this.pigment,
      };
      this.$emit('open-info-modal', nodeStructure);
    },
  },
  computed: {
    imageSrc() {
      const src = this.pigment.pigment_image?.url || this.defaultImageSrc;
      return src;
    },
    defaultImageSrc() {
      return `https://placehold.co/${this.size}?text=No+Image`; // Fallback URL based on size
    },
    pigmentPath() {
      return PigmentPath;
    },
    containerStyle() {
      return {
        position: 'relative',
        display: 'inline-block',
      };
    },
    imageStyle() {
      return {
        width: `${this.size}px`,
        height: `${this.size}px`,
      };
    },
  },
};
</script>

<style scoped>
.pigment-image {
  background-size: cover;
  background-position: center;
  position: relative;
}

.cutout-image {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  background-size: cover;
  background-position: center;
}

.greyed-out {
  opacity: 0.5;
  filter: grayscale(100%);
  transition: opacity 0.3s ease;
}

.pigment-label {
  text-align: center;
}

.pigment-info-modal-button {
  position: absolute;
  top: 5px;
  right: 5px;
  background: none;
  border: none;
  padding: 0;
  z-index: 10;
  cursor: pointer;
}

.info-icon {
  width: 1.5rem;
  height: 1.5rem;
}

.pigment-link {
  display: block;
  text-decoration: none;
  color: inherit;
}
</style>
