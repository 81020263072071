<template>
  <div class="container mx-auto">
    <h1 class="h1-title">Browse by Pigments</h1>
    <div class="search-filters-top-container pigments-filter">
      <button
        :class="[
          'search-filter-button blue-hover',
          { active: currentView === 'list' },
        ]"
        @click="setView('list')"
      >
        List View
      </button>
      <button
        :class="[
          'search-filter-button blue-hover',
          { active: currentView === 'hierarchy' },
        ]"
        @click="setView('hierarchy')"
      >
        Hierarchy View
      </button>
    </div>

    <!-- List View -->
    <div
      v-if="currentView === 'list'"
      class="flex flex-wrap mt-20 main-thumb-container"
      id="pigments-list-container"
    >
      <div v-if="isLoadingPigments">Loading pigments...</div>
      <span
        v-else
        class="h-auto mb-14 mx-7 relative pigment-item"
        v-for="pigment in visiblePigments"
        :key="pigment.name"
      >
        <PigmentImage
          :pigment="pigment.pigment"
          :size="200"
          :greyedOut="pigment.pigment.rolled_up_analysis_count === 0"
          :showTitle="true"
          :showCount="true"
          :showInfoButton="true"
          buttonClass="list-view-modal-button"
          :routeTarget="{
            name: 'search-view',
            query: {
              works: {
                refinementList: {
                  pigment_paths: [pigment.pigment.display_path],
                },
              },
            },
          }"
          @open-info-modal="openModal"
        />
      </span>
    </div>

    <!-- Hierarchy View -->
    <div v-else class="mt-20" id="hierarchy-container">
      <div v-if="isLoadingHierarchy" class="text-center" id="hierarchy-loading">
        Loading hierarchy...
      </div>
      <HierarchyView
        v-else
        :hierarchy="fullHierarchy"
        :selectedPigmentId="selectedPigment ? selectedPigment.pigment.id : -1"
        @open-info-modal="openModalFromHierarchy"
      />
    </div>

    <!-- Pigment Info Modal -->
    <PigmentInfoModal
      v-if="selectedPigment"
      :show="showModal"
      :pigment="selectedPigment"
      :hierarchyBranch="hierarchyBranch"
      :loading="isLoadingBranch"
      @close="closeModal"
    />
  </div>
</template>

<script>
import PigmentImage from '../components/PigmentImage.vue';
import { inject } from 'vue';
import PigmentInfoModal from '../components/PigmentInfoModal.vue';
import HierarchyView from '../components/HierarchyView.vue';

export default {
  name: 'PigmentsView',
  components: {
    PigmentInfoModal,
    HierarchyView,
    PigmentImage,
  },

  setup() {
    const updateCiteType = inject('updateCiteType');
    updateCiteType('pigments');
  },

  data() {
    return {
      allPigments: [],
      currentView: 'list',
      categories: [],
      openCategories: [],
      isLoadingPigments: false,
      isLoadingHierarchy: false,
      isLoadingBranch: false,
      showModal: false,
      selectedPigment: null,
      fullHierarchy: [],
      hierarchyBranch: {},
    };
  },
  methods: {
    setView(view) {
      this.currentView = view;
      // Update URL without navigation
      const query = { ...this.$route.query, view };
      this.$router.replace({ query });
    },
    toggleCategory(categoryName) {
      const index = this.openCategories.indexOf(categoryName);
      if (index === -1) {
        this.openCategories.push(categoryName);
      } else {
        this.openCategories.splice(index, 1);
      }
    },
    async fetchPigments() {
      try {
        this.isLoadingPigments = true;
        const response = await fetch('/api/pigments/');
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        this.allPigments = data;
        this.categories = [
          ...new Set(
            this.allPigments.map((pigment) => pigment.pigment.root_category),
          ),
        ].sort();
      } catch (error) {
        console.error('Error fetching pigments:', error);
      } finally {
        this.isLoadingPigments = false;
      }
    },
    async fetchPigmentHierarchy() {
      try {
        this.isLoadingHierarchy = true;
        const response = await fetch('/api/hierarchy/');
        this.fullHierarchy = await response.json();
      } catch (error) {
        console.error('Error fetching hierarchy:', error);
      } finally {
        this.isLoadingHierarchy = false;
      }
    },
    async fetchHierarchyBranch(pigmentId) {
      // Could be optimized by fetching the hierarchy once and then filtering the data
      // to just return the branch for the selected pigment
      try {
        this.isLoadingBranch = true;
        const response = await fetch(`/api/hierarchy/${pigmentId}/`);
        this.hierarchyBranch = await response.json();
      } catch (error) {
        console.error('Error fetching hierarchy branch:', error);
      } finally {
        this.isLoadingBranch = false;
      }
    },
    openModal(pigment) {
      this.selectedPigment = pigment;
      this.fetchHierarchyBranch(pigment.pigment.id);
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
      this.selectedPigment = null;
      this.hierarchyBranch = {};
    },
    openModalFromHierarchy(node) {
      if (node && node.pigment) {
        const pigment = { pigment: node.pigment };
        this.selectedPigment = pigment;
        this.fetchHierarchyBranch(pigment.pigment.id);
        this.showModal = true;
      } else {
        console.error('Invalid node structure received:', node);
      }
    },
  },
  async created() {
    // Set initial view from URL parameter
    const urlParams = new URLSearchParams(window.location.search);
    const viewParam = urlParams.get('view');
    if (viewParam === 'hierarchy') {
      this.setView('hierarchy');
    } else {
      this.setView('list'); // Default to list view
    }

    this.isLoadingPigments = true;
    this.isLoadingHierarchy = true;
    try {
      await this.fetchPigments();
      await this.fetchPigmentHierarchy();
    } catch (error) {
      console.error('Error fetching initial data:', error);
    } finally {
      this.isLoadingPigments = false;
      this.isLoadingHierarchy = false;
    }
  },
  computed: {
    visiblePigments() {
      // filter to ensure at least one published Analysis and sort by name
      return this.allPigments
        .filter((pigment) => pigment.pigment.published_analysis_count > 0)
        .sort((a, b) => a.pigment.name.localeCompare(b.pigment.name));
    },
  },
};
</script>

<style scoped>
.pigments-filter {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.search-filter-button.active {
  background-color: #9bb0dd;
}

:deep(.list-view-modal-button) {
  top: 2rem;
  right: 2rem;
}
</style>
