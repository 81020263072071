<template>
  <div v-if="show" class="modal-overlay" @click.self="close">
    <div class="modal-content">
      <button class="close-button" @click="close" aria-label="Close modal">
        <svg width="24" height="24" viewBox="0 0 24 24">
          <path
            d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
          />
        </svg>
      </button>

      <section class="pigment-modal-section pigment-info">
        <h2 class="section-title">Pigment Info</h2>
        <hr />
        <div class="pigment-info-container">
          <PigmentImage :pigment="pigment.pigment" :size="100" />
          <div class="pigment-details">
            <h3 class="pigment-name">
              {{ pigment.pigment.name
              }}<span v-if="pigment.pigment.chemical_composition"
                >, {{ pigment.pigment.chemical_composition }}</span
              >
            </h3>
            <p v-if="pigment.pigment.description" class="description">
              {{ pigment.pigment.description }}
            </p>
          </div>
        </div>
      </section>

      <section class="links-container">
        <a
          v-if="pigment.pigment.mfa_uri"
          :href="pigment.pigment.mfa_uri"
          target="_blank"
          class="cameo-button"
        >
          View {{ pigment.pigment.name }} on Cameo
        </a>
        <router-link
          class="search-link-button"
          :to="{
            name: 'search-view',
            query: {
              works: {
                refinementList: {
                  pigment_paths: [pigment.pigment.display_path],
                },
              },
            },
          }"
        >
          View MCH Works with {{ pigment.pigment.name }}
        </router-link>
      </section>

      <section
        v-if="pigment.pigment.suggested_analysis_methodologies?.length"
        class="pigment-modal-section"
      >
        <h2 class="section-title">Suggested Analysis Methodologies</h2>
        <hr />
        <p class="suggested-analysis-methodologies">
          <span
            v-for="(methodology, index) in pigment.pigment
              .suggested_analysis_methodologies"
            :key="methodology"
          >
            {{ methodology
            }}<span
              v-if="
                index <
                pigment.pigment.suggested_analysis_methodologies.length - 1
              "
              >,
            </span>
          </span>
        </p>
      </section>

      <section
        v-if="pigment.pigment.alternate_spellings?.length"
        class="pigment-modal-section"
      >
        <h2 class="section-title">Alternative Terms</h2>
        <hr />
        <p class="alt-terms">{{ pigment.pigment.alternate_spellings }}</p>
      </section>

      <section class="pigment-modal-section">
        <h2 class="section-title">Pigment Hierarchy</h2>
        <hr />
        <div v-if="loading">Loading hierarchy data...</div>
        <div v-if="!loading && hierarchyBranch" class="hierarchy-display">
          <HierarchyNode
            :node="hierarchyBranch"
            :isSelected="hierarchyBranch.pigment.id === pigment.pigment.id"
            :selectedPigmentId="pigment.pigment.id"
          />
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import PigmentImage from './PigmentImage.vue';
import HierarchyNode from './HierarchyNode.vue';

export default {
  name: 'PigmentInfoModal',

  props: {
    show: {
      type: Boolean,
      required: true,
    },
    pigment: {
      type: Object,
      required: true,
    },
    hierarchyBranch: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    HierarchyNode,
    PigmentImage,
  },

  methods: {
    close() {
      this.$emit('close');
    },
    handleEsc(e) {
      if (e.key === 'Escape' && this.show) {
        this.close();
      }
    },
  },

  mounted() {
    document.addEventListener('keydown', this.handleEsc);
  },

  unmounted() {
    document.removeEventListener('keydown', this.handleEsc);
  },
};
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 2rem;
  border-radius: 8px;
  max-width: 600px;
  width: 90%;
  max-height: 90vh;
  overflow-y: auto;
  position: relative;
}

.close-button {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: none;
  border: none;
  cursor: pointer;
  padding: 4px;
}

.modal-title {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.pigment-info-container {
  display: flex;
  align-items: flex-start;
}

.pigment-image {
  position: relative;
  margin-right: 1rem;
}

.pigment-image img {
  width: 100px;
  height: 100px;
}

.pigment-name {
  font-size: 1.25rem;
  font-weight: bold;
}

.section-title {
  font-size: 0.9rem;
  text-transform: uppercase;
  margin: 1rem 0 0;
  font-weight: bold;
}

hr {
  border-top: 1px solid lightgrey;
  margin-bottom: 0.5rem;
}

.hierarchy-display {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.hierarchy-level {
  padding: 0.5rem;
  background: #f3f4f6;
  border-radius: 4px;
}

.links-container {
  display: flex;
  gap: 10px;
  margin-top: 1rem;
  flex-wrap: wrap;
}

.cameo-button,
.search-link-button {
  flex: 1;
  min-width: 150px;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 6px 10px;
  height: auto;
  min-height: 32px;
  box-sizing: border-box;
}

.cameo-section,
.search-link-section {
  margin-top: 0;
}

.alt-terms,
.suggested-analysis-methodologies {
  font-size: 0.8rem;
  margin: 0;
}

.pigment-info {
  display: flex;
  flex-direction: column;
}
</style>
