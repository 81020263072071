<template>
  <div id="hierarchy-view" role="tree" aria-label="Pigment Hierarchy">
    <div
      v-for="rootNode in hierarchy"
      :key="rootNode.id"
      class="root-node"
      role="treeitem"
      :aria-expanded="isOpen(rootNode.pigment.id)"
      tabindex="0"
      @keydown.enter="toggleRoot(rootNode.pigment.id)"
      @keydown.space.prevent="toggleRoot(rootNode.pigment.id)"
    >
      <div
        class="root-node-header"
        @click="toggleRoot(rootNode.pigment.id)"
        :aria-label="`${rootNode.pigment.name} category. ${isOpen(rootNode.pigment.id) ? 'Expanded' : 'Collapsed'}`"
        v-if="rootNode.children.length > 0"
      >
        <h2 class="title">{{ rootNode.pigment.name }}</h2>
        <span class="toggle-icon" aria-hidden="true">
          <PhCaretUp
            v-if="isOpen(rootNode.pigment.id)"
            :size="24"
            weight="bold"
          />
          <PhCaretDown v-else :size="24" weight="bold" />
        </span>
      </div>
      <div
        v-if="isOpen(rootNode.pigment.id)"
        class="level-two-container"
        role="group"
      >
        <div
          v-for="levelTwoNode in rootNode.children"
          :key="levelTwoNode.pigment.id"
          class="level-two-node"
          role="treeitem"
        >
          <div class="level-two-header">
            <div class="pigment-container">
              <PigmentImage
                :pigment="levelTwoNode.pigment"
                :size="150"
                :greyedOut="levelTwoNode.pigment.rolled_up_analysis_count === 0"
                :showTitle="true"
                :showCount="true"
                :showInfoButton="true"
                buttonClass="level-two-button"
                :routeTarget="{
                  name: 'search-view',
                  query: {
                    works: {
                      refinementList: {
                        pigment_paths: [levelTwoNode.pigment.display_path],
                      },
                    },
                  },
                }"
                @open-info-modal="$emit('open-info-modal', $event)"
              />
            </div>
            <span v-if="levelTwoNode.children.length > 0" class="arrow-icon"
              >↓</span
            >
          </div>
          <div
            class="level-three-container"
            v-if="levelTwoNode.children.length > 0"
          >
            <div
              v-for="levelThreeNode in levelTwoNode.children"
              :key="levelThreeNode.pigment.id"
              class="level-three-node"
            >
              <div class="level-three-header">
                <div class="pigment-container">
                  <PigmentImage
                    :pigment="levelThreeNode.pigment"
                    :size="100"
                    :greyedOut="
                      levelThreeNode.pigment.rolled_up_analysis_count === 0
                    "
                    :showTitle="true"
                    :showCount="true"
                    :showInfoButton="true"
                    buttonClass="level-three-button"
                    fontClass="text-md"
                    :routeTarget="{
                      name: 'search-view',
                      query: {
                        works: {
                          refinementList: {
                            pigment_paths: [
                              levelThreeNode.pigment.display_path,
                            ],
                          },
                        },
                      },
                    }"
                    @open-info-modal="$emit('open-info-modal', $event)"
                  />
                </div>
                <span
                  v-if="levelThreeNode.children.length > 0"
                  class="arrow-icon"
                  >↓</span
                >
              </div>
              <div
                class="level-four-container"
                v-if="levelThreeNode.children.length > 0"
              >
                <div
                  v-for="levelFourNode in levelThreeNode.children"
                  :key="levelFourNode.pigment.id"
                  class="level-four-node"
                >
                  <div class="pigment-container">
                    <PigmentImage
                      :pigment="levelFourNode.pigment"
                      :size="75"
                      :greyedOut="
                        levelFourNode.pigment.rolled_up_analysis_count === 0
                      "
                      :showTitle="true"
                      :showCount="true"
                      :showInfoButton="true"
                      buttonClass="level-four-button"
                      fontClass="text-sm"
                      :routeTarget="{
                        name: 'search-view',
                        query: {
                          works: {
                            refinementList: {
                              pigment_paths: [
                                levelFourNode.pigment.display_path,
                              ],
                            },
                          },
                        },
                      }"
                      @open-info-modal="$emit('open-info-modal', $event)"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PigmentImage from './PigmentImage.vue';
import { PhCaretDown } from '@phosphor-icons/vue';
import { PhCaretUp } from '@phosphor-icons/vue';

export default {
  name: 'HierarchyView',
  components: {
    PigmentImage,
    PhCaretDown,
    PhCaretUp,
  },
  props: {
    hierarchy: {
      type: Array,
      required: true,
    },
  },
  emits: ['open-info-modal'],
  data() {
    return {
      openRoots: [],
    };
  },
  methods: {
    toggleRoot(rootId) {
      const index = this.openRoots.indexOf(rootId);
      if (index === -1) {
        this.openRoots.push(rootId);
      } else {
        this.openRoots.splice(index, 1);
      }
    },
    isOpen(rootId) {
      return this.openRoots.includes(rootId);
    },
  },
};
</script>

<style scoped>
.root-node {
  margin-bottom: 0;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}

.root-node-header {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
}

.title {
  flex-grow: 1;
  text-align: center;
  font-size: 1.5rem;
  margin: 0;
}

.toggle-icon {
  margin-left: 10px;
}

.level-two-header,
.level-three-header,
.level-four-container {
  text-align: center;
}

.level-two-header,
.level-three-header {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.level-two-container {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
}

.level-two-node {
  margin-right: 0;
  padding: 10px;
  border-right: 1px solid #ccc;
}

.level-two-node:last-child {
  border-right: none;
}

.level-three-container {
  display: flex;
  flex-direction: row;
}

.level-three-node {
  margin-right: 0;
  padding: 10px;
  border-right: 1px solid #ccc;
}

.level-three-node:last-child {
  border-right: none;
}

.level-four-container {
  display: flex;
  flex-direction: column;
}

.level-four-node {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.arrow-icon {
  display: block;
  text-align: center;
  margin-top: 5px;
}

.pigment-container {
  position: relative;
  display: inline-block;
}

.pigment-info-modal-button {
  position: absolute;
  top: 5px;
  right: 5px;
  background: none;
  border: none;
  padding: 0;
  z-index: 10;
  cursor: pointer;
}

.info-icon {
  width: 1.5rem;
  height: 1.5rem;
}

/* Level-specific button positioning */
:deep(.level-two-button) {
  top: 20px;
  right: 20px;
}

:deep(.level-two-button .info-icon) {
  width: 1.5rem;
  height: 1.5rem;
}

:deep(.level-three-button) {
  top: 15px;
  right: 15px;
}

:deep(.level-three-button .info-icon) {
  width: 1.35rem;
  height: 1.35rem;
}

:deep(.level-four-button) {
  top: 10px;
  right: 10px;
}

:deep(.level-four-button .info-icon) {
  width: 1.2rem;
  height: 1.2rem;
}
</style>
