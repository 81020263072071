<template>
  <div id="mirador-container" :key="manifestKey">
    <div id="mirador"></div>
  </div>
</template>

<script>
import Mirador from 'mirador';
// import { miradorImageToolsPlugin } from 'mirador-image-tools';
// import CatchPyAdapter from 'mirador-annotations/es/CatchPyAdapter';
// import miradorAnnotationPlugins from 'mirador-annotations/es/index';

export default {
  name: 'MiradorVue',
  props: {
    manifestUrl: {
      type: String,
    },
    config: {
      type: Object,
      default() {
        return {
          id: 'mirador',
          window: {
            allowClose: false,
            allowFullscreen: true,
            allowMaximize: false,
            panels: {
              annotations: true,
            },
            defaultSideBarPanel: 'info',
            sideBarOpenByDefault: false,
          },
          workspace: {
            draggingEnabled: false,
            allowNewWindows: false,
            showZoomControls: true,
          },
          selectedTheme: 'mcih',
          themes: {
            mcih: {
              palette: {
                type: 'light',
                typography: {
                  fontFamily: ['Agrandir', 'ui-sans-serif'],
                },
                primary: {
                  main: '#731422',
                },
              },
            },
          },
          workspaceControlPanel: {
            enabled: false,
          },
          annotations: {
            htmlSanitizationRuleSet: 'iiif', // See src/lib/htmlRules.js for acceptable values
            filteredMotivations: [
              'oa:tagging',
              'sc:painting',
              'commenting',
              'tagging',
            ], // Not including 'oa:commenting' to filter out HAM annos
          },
        };
      },
    },
    pluginNames: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      plugins: [],
      localConfig: { ...this.config }, // make a local copy of the config so we can mutate it
    };
  },
  mounted() {
    this.$nextTick(function () {
      // render Mirador
      // Use default config unless passed

      // Add plugins
      // if (this.pluginNames) {
      //   this.pluginNames.forEach((pluginName) => this.addPlugin(pluginName));
      // }
      // render Mirador using the local copy of the config
      const miradorInstance = Mirador.viewer(this.localConfig, this.plugins);
      window.miradorInstance = miradorInstance;
      window.Mirador = Mirador;
    });
  },
  computed: {
    manifestKey() {
      // use the manifestUrl as the key
      return this.manifestUrl;
    },
    // catchPyConfig() {
    //   return window.catchPyConfig;
    // },
  },
  watch: {
    manifestUrl: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.initMirador();
        }
      },
    },
  },
  methods: {
    initMirador() {
      this.$nextTick(function () {
        // Dispose of previous Mirador instance if necessary
        // Any teardown logic for the old Mirador instance

        // Initialize Mirador with the updated manifestUrl
        this.plugins = []; // Reset plugins or reinitialize as necessary

        // Add plugins
        if (this.pluginNames) {
          this.pluginNames.forEach((pluginName) => this.addPlugin(pluginName));
        }

        // Ensure the config object is updated with the new manifestUrl as needed

        const miradorInstance = Mirador.viewer(this.config, this.plugins);
        window.miradorInstance = miradorInstance;
      });
    },
    addPlugin(name) {
      switch (name) {
        // case 'miradorImageToolsPlugin':
        //   this.plugins.push(...miradorImageToolsPlugin);
        //   break;
        // case 'catchPy': {
        //   // let manifestUrl = this.manifestUrl;
        //   let username, fullname;
        //   let contextId = this.catchPyConfig.contextId || '1';
        //   let collectionId = this.catchPyConfig.collectionId || '1';
        //   username = '';
        //   fullname = '';

        //   // Need to hide the "create anno" button on the frontend

        //   const updatedConfig = { ...this.localConfig };
        //   updatedConfig.annotation = {
        //     // adapter: (manifestUrl) => new CatchPyAdapter(
        //     // this.manifestUrl,
        //     adapter: (canvasId) =>
        //       new CatchPyAdapter(
        //         canvasId,
        //         this.catchPyConfig.endpoint,
        //         // catchPyConfig.user_id, // needed?
        //         username,
        //         // catchPyConfig.user_fullname, // needed?
        //         fullname,
        //         this.catchPyConfig.platform_name,
        //         contextId,
        //         collectionId,
        //         this.catchPyConfig.jwt,
        //       ),
        //     hideAddAnnotationButton: true,
        //   };
        //   this.localConfig = updatedConfig;
        //   this.plugins.push(...miradorAnnotationPlugins);
        //   break;
        // }
        default:
          break;
      }
    },
  },
};
</script>
